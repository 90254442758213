import { SubRepatriationFormMedical } from './models/subRepatriationFormMedical';
import { Message } from '@secca/case/components/case-output-management/models/message';
import { combineLatest, EMPTY, filter, Subscription, timer } from 'rxjs';
import { CaseLockHelperService } from '@secca/core/services/case-lock-helper.service';
import { ServiceOrder, ServiceOrderAdapter, ServiceOrderModelMerger } from '@secca/shared/models/service-order/service-order';
import { ServiceOrderTypeState } from './models/serviceOrderTypeState';
import { ServiceTypeComponent, ValidatingFieldsServiceTypeComponent } from './models/interfaces';
import * as lodash from 'lodash-es';
import * as _ from 'lodash-es';
import {
  AttachmentTypeEnum,
  CommitmentTypes,
  GopMethodEnum,
  MessageChannelType,
  PermissionEnum,
  PhoneNumberType,
  PreferredChannelEnum,
  ServiceTypeEnum,
  ShortcutEnum,
  StakeholderTypeOrder,
  StatusTypes,
  ValidationTypeEnum
} from '@secca/shared/models/enums';
import { PlanService } from '@secca/core/services/plan.service';
import { AutoUnsubscribe } from 'src/app/shared/decorators/auto-unsubscribe';
import { CaseIncident } from '@secca/shared/models/caseIncident';
import { ServiceOrderService } from '@secca/core/services/service-order.service';
import { InputTypePattern, StakeholderTypeEnum } from 'src/app/shared/models/enums';
import { CaseService } from 'src/app/core/services/case.service';
import { AutoCompleteTypeEnum } from 'src/app/shared/components/drop-down-countries-auto-complete/auto-complete-type-enum';
import { DropdownDictionary } from '@secca/shared/models/dropdownDictionary';
import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { CaseStakeholder } from '@secca/shared/models/caseStakeholder';
import { MedicalAssessmentServiceOrderExtension } from '@secca/shared/models/service-order/medical-assessment-service-order-extension';
import { SubStakeholder } from '@secca/case/components/case-plans/case-plans/add-service-order/models/subStakeholder';
import { ServiceOrderMessageRequest } from '@secca/shared/models/service-order/service-order-message-request';
import { ServiceOrderMessageService } from '@secca/core/services/service-order-message.service';
import { OutputManagementService } from '@secca/case/components/case-output-management/services/output-management.service';
import { PhoneNumber } from '@secca/shared/models/phoneNumber';
import { FaxDto } from '@secca/shared/models/fax-dto';
import { PermissionService } from '@secca/core/services/permission.service';
import { InsuranceService } from '@secca/core/services/insurance.service';
import { AttachmentDto } from '@secca/shared/models/attachment-dto';
import { CaseStateService } from '@secca/core/state-services/case-state.service';
import { InputValidationService } from '@secca/core/services/input-validation.service';
import { ShortcutService } from '@secca/core/services/shortcut.service';
import { Clipboard } from '@angular/cdk/clipboard';
import { MenuService } from '@secca/core/services/menu.service';
import { CaseSelectedMenuEnum } from '@secca/shared/enums/case-selected-menu-enum.component';
import { CaseDialogViewerService } from '@secca/case/case-dialog-viewer.service';
import { ProviderService } from '@secca/core/services/providerService';
import { ServiceOrderType } from '@secca/shared/models/service-order-type';
import { DropdownWithIconDisableDictionary } from '@secca/shared/models/dropdownWithIconDisableDictionary';
import { CASE_TYPE_CODES, CaseType } from '@secca/shared/models/case-type';
import { CustomerProfileInfo } from '@secca/shared/models/customerProfileInfo';
import { CoordinationCaseService } from '@secca/core/services/coordination-case.service';
import { TranslateService } from '@ngx-translate/core';
import { MasterListService } from '@secca/core/services/masterlist-service';
import { PropertyTypeDb } from '@secca/shared/models/PropertyType';
import {
  SupplierSearchHelper
} from '@secca/case/components/case-plans/case-plans/add-service-order/search-service-provider/search-service-provider-modal/supplier-search-helper.service';
import { SupplierSearchResponse } from '@secca/shared/models/SupplierSearchResponse';
import { catchError, finalize, map, switchMap, tap } from 'rxjs/operators';
import { DictionaryService } from '@secca/core/services/dictionary.service';
import { UserDto } from '@secca/shared/models/userDto';

const ICC_LINK_SERVICE_TYPES = [
  ServiceTypeEnum.REGULAR_FLIGHT_NON_MEDICAL,
  ServiceTypeEnum.REGULAR_FLIGHT_MEDICAL,
  ServiceTypeEnum.AMBULANCE_FLIGHT
];

@Component({
  selector: 'app-add-service-order',
  templateUrl: './add-service-order.component.html',
  styleUrls: ['./add-service-order.component.scss']
})
@AutoUnsubscribe
export class AddServiceOrderComponent implements OnInit, OnDestroy {
  @ViewChild('ServiceTypeComponent') serviceTypeComponent: ServiceTypeComponent;
  selectedServiceTypeComponent: ServiceTypeComponent;
  hoverContent = '';
  setTimerUsed: boolean = false;
  SUPPLIER_PROFILE_CODE_EGENCIA = 'SP045001';
  SUPPLIER_PROFILE_CODE_GETE = 'SP031001';
  SUPPLIER_PROFILE_CODE_ALBIN = 'SP044001';

  AOO_TYPE = 'PRIMARY';

  @Output() closeEvent = new EventEmitter();
  private loggedInUser: UserDto;
  private contactRoles: PropertyTypeDb[];

  @Input() set editedServiceOrder(editedServiceOrder: ServiceOrder) {
    this.selectedServiceOrder = editedServiceOrder;
    this.selectedServiceOrderInitial = this.stringifyAndSanitizeServiceOrder(editedServiceOrder);
  }

  get editedServiceOrder(): ServiceOrder {
    return this.selectedServiceOrder;
  }

  @Input()
  set caseId(newCaseId: string) {
    if (newCaseId !== undefined) {
      this._caseId = +newCaseId;
    }
  }

  get caseId(): string {
    return this._caseId.toString();
  }

  @Input()
  set caseNumber(newCaseNumber: string) {
    if (newCaseNumber !== undefined) {
      this._caseNumber = newCaseNumber;
    }
  }

  get caseNumber(): string {
    return this._caseNumber;
  }

  @Input() incidentId: number;
  @Input() serviceOrderId: number;
  displayServiceOrderId: string;
  selectedServiceOrder: ServiceOrder;
  iccServiceOrder: ServiceOrder;
  selectedServiceOrderInitialStatus: StatusTypes;
  selectedServiceOrderType: ServiceOrderType;
  gopPreviewedServiceOrderInitial: string;
  selectedServiceOrderInitial: string;
  serviceTypeEnum = ServiceTypeEnum;
  incident = new CaseIncident();
  private _caseId: number;
  private _caseNumber: string;
  availableServiceOrderTypes: ServiceOrderType[];
  types: DropdownWithIconDisableDictionary[];
  providers: DropdownDictionary[];
  stakeholders: CaseStakeholder[];
  returnedProviders: CaseStakeholder[];
  isValid = false;
  editModal: boolean;
  showModal: boolean;
  saveWorkPending: boolean;
  defaultBillingCurrency: string;
  serviceOrderTypeState: ServiceOrderTypeState = new ServiceOrderTypeState();
  $editSubscr;
  $covSubscr;
  $createGopEventSubscr;
  $sendGopEventSubscr;
  $createPreAssessmentGopSubscr;
  $sendConfirmEventSubscr;
  $timerSubscr: Subscription;
  $stateChangetimerSubscr: Subscription;
  supplier: CaseStakeholder;
  egenciaStakeholderId: number;
  getEInternationalStakeholderId: number;
  albinStakeholderId: number;
  isSaveButtonStateChangesEnabled = false;
  caseType: CaseType;
  customerProfileInfo: CustomerProfileInfo;
  showProviderSpinner: boolean = false;
  ccContactTypes: String[];
  baseCurrenciesSupported: DropdownDictionary[] = [];
  private selectedMenuId: number;
  private shortcutSubscriptions: Subscription[] = [];

  constructor(
    private planService: PlanService,
    private caseService: CaseService,
    private serviceOrderService: ServiceOrderService,
    private menuService: MenuService,
    private shortcutService: ShortcutService,
    private clipboard: Clipboard,
    private outputManagementService: OutputManagementService,
    private serviceOrderAdapter: ServiceOrderAdapter,
    private serviceOrderModelMerger: ServiceOrderModelMerger,
    private messageService: ServiceOrderMessageService,
    private inputValidationService: InputValidationService,
    public caseLockHelperService: CaseLockHelperService,
    public permissionService: PermissionService,
    private insuranceService: InsuranceService,
    public caseStateService: CaseStateService,
    private dialogViewerService: CaseDialogViewerService,
    private translateService: TranslateService,
    private coordinationCaseService: CoordinationCaseService,
    private masterListService: MasterListService,
    private supplierSearchHelper: SupplierSearchHelper,
    private supplierService: ProviderService,
    private dictionaryService: DictionaryService
  ) {
    this.shortcutSubscriptions.push(
      this.shortcutService.addShortcut({ keys: ShortcutEnum.ServiceOrderCopyId }).subscribe(a => {
        this.clipboard.copy(`${this.caseNumber}-${this.displayServiceOrderId}`);
      }),
      this.shortcutService.addShortcut({ keys: ShortcutEnum.SaveAction }).subscribe(a => {
        this.selectedMenuId = this.menuService.getSelectedMenuId(parseInt(this.caseId, 10));
        if (this.selectedMenuId === CaseSelectedMenuEnum.CasePlan) {
          if (!this.disableSaveButton()) {
            this.saveServiceOrder(false);
          }
        }
      }),
      this.caseStateService.getCaseType().subscribe(caseType => this.caseTypeUpdated(caseType)),
      this.caseStateService.getServiceOrderChange().subscribe(() => this.serviceOrderUpdated())
    );
    this.masterListService.getContactRoles().subscribe((value: PropertyTypeDb[]) => {
      this.ccContactTypes = value.filter(contactRole => contactRole.gopSecondary).map(contactRole => contactRole.businessKey);
      this.contactRoles = value;
    });
    this.dictionaryService.getLoggedInUser().subscribe(user => {
      this.loggedInUser = user;
    });
    this.dictionaryService.getBaseCurrenciesSupported().subscribe(currencies => {
      currencies.forEach(element => {
        this.baseCurrenciesSupported.push(new DropdownDictionary(element, element));
      });
    });
  }

  get InputTypePattern() {
    return InputTypePattern;
  }

  ngOnInit() {
    this.egenciaStakeholderId = null;
    this.albinStakeholderId = null;
    this.getEInternationalStakeholderId = null;
    this.getServiceOrderTypes(this.caseId);
  }

  ngOnDestroy(): void {
    this.shortcutSubscriptions.forEach(subscription => subscription.unsubscribe());
  }

  get hasGopPreviewModelChanged(): boolean {
    if (this.gopPreviewedServiceOrderInitial == null || !this.serviceOrderTypeState.gopHasBeenPreviewed) {
      return false;
    }
    const result =
      this.stringifyAndSanitizeServiceOrder(this.selectedServiceOrder).normalize() === this.gopPreviewedServiceOrderInitial.normalize();
    if (!result) {
      this.serviceOrderTypeState.gopHasBeenPreviewed = false;
    }
    return result;
  }

  hasModelChanged(): boolean {
    return !lodash.isEqual(this.stringifyAndSanitizeServiceOrder(this.selectedServiceOrder), this.selectedServiceOrderInitial);
  }

  // aka create new service order
  public show() {
    this.serviceOrderTypeState.isLeftSideValid = false;
    this.selectedServiceOrder = new ServiceOrder();
    this.selectedServiceOrderInitialStatus = StatusTypes.EXPECTED;
    this.resetSelectedServiceOrder();
    this.planService.getBillingCurrencyForCase(+this.caseId).subscribe(result => {
      this.defaultBillingCurrency = result.billingCurrency;
    });
    this.loadStakeholders();
    this.showModal = true;
    this.serviceOrderService.reserveServiceOrderId(+this.caseId).subscribe(result => this.displayServiceOrderId = result.serviceOrderId);
  }

  public showEdit(serviceOrderId: number) {
    this.showModal = true;
    this.editModal = true;
    this.disableSaveButtonStateChanges();
    this.resetSelectedServiceOrder();
    this.loadStakeholders();
    this.serviceOrderTypeState.isLeftSideValid = true;

    this.planService.getBillingCurrencyForCase(+this.caseId).subscribe(result => {
      this.defaultBillingCurrency = result.billingCurrency;
    });
    this.serviceOrderService.getServiceOrder(serviceOrderId).subscribe(result => {
      this.selectedServiceOrder = result;
      if ( this.selectedServiceOrder && ICC_LINK_SERVICE_TYPES.includes(this.selectedServiceOrder.type) && this.selectedServiceOrder.id) {
        this.coordinationCaseService.getCoordinationCaseServiceOrdersFromLinkedServiceOrder(this._caseId,this.selectedServiceOrder).subscribe(linked => {
          this.iccServiceOrder = linked;
          if(!!this.iccServiceOrder) {
            this.selectedServiceOrder.isLinkedToIcc = true;
          }
        });
      }

      this.selectedServiceOrderType = this.availableServiceOrderTypes.find(type => type.serviceTypeEnumValue == this.selectedServiceOrder.type);
      this.selectedServiceOrderInitialStatus = this.selectedServiceOrder.status;
      this.selectedServiceOrderInitial = this.stringifyAndSanitizeServiceOrder(this.selectedServiceOrder);
      if (this.selectedServiceOrder.status === StatusTypes.COMMITTED) {
        this.serviceOrderTypeState.isConfirmationTabSaved = true;
      }
      this.serviceOrderTypeState.requestSent = this.selectedServiceOrder.requestSent;
      this.refreshComponentsInNextAngularCycle();
      this.checkSupplierAndAgentDetails(this.selectedServiceOrder.supplierId);
      this.enableSaveButtonStateChanges();
      this.displayServiceOrderId = result.serviceOrderId;
    });
  }

  private resetSelectedServiceOrder() {
    // TODO Move this
    this.selectedServiceOrder = new ServiceOrder({
      type: null,
      caseId: +this.caseId,
      extension: null,
      status: StatusTypes.EXPECTED
    });
    this.serviceOrderTypeState = new ServiceOrderTypeState();
    this.checkSupplierAndAgentDetails();
    this.iccServiceOrder = null;

    if (this.$createGopEventSubscr != null) {
      this.$createGopEventSubscr.unsubscribe();
    }
    if (this.$sendGopEventSubscr != null) {
      this.$sendGopEventSubscr.unsubscribe();
    }
    if (this.$sendConfirmEventSubscr != null) {
      this.$sendConfirmEventSubscr.unsubscribe();
    }
    if (this.$createPreAssessmentGopSubscr != null) {
      this.$createPreAssessmentGopSubscr.unsubscribe();
    }
    this.$createGopEventSubscr = this.serviceOrderTypeState.previewGopEvent.subscribe(e => {
      if (this.selectedServiceOrder.serviceOrderId == null) {
        this.selectedServiceOrder.serviceOrderId = this.displayServiceOrderId;
        this.serviceOrderService.createServiceOrder(this.selectedServiceOrder).subscribe(serviceOrder => {
          this.serviceOrderId = serviceOrder.id;
          this.selectedServiceOrder = this.serviceOrderAdapter.adapt(serviceOrder);
          this.gopPreviewedServiceOrderInitial = this.stringifyAndSanitizeServiceOrder(this.selectedServiceOrder);
          this.selectedServiceOrderInitialStatus = this.selectedServiceOrder.status;
          this.serviceOrderService.previewGop(this.selectedServiceOrder).subscribe(data => {
            this.serviceOrderTypeState.gopHasBeenPreviewed = true;
            const file = new Blob([data], { type: 'application/pdf' });
            const fileURL = URL.createObjectURL(file);
            this.dialogViewerService.openPreviewDocumentDialog(this.serviceOrderId, fileURL, serviceOrder.caseId.toString(), this.caseNumber);
            this.editModal = true;
            this.serviceOrderTypeState.gopPreviewWasClicked = true;
          });
        });
      } else {
        this.serviceOrderService.editServiceOrder(this.selectedServiceOrder).subscribe(result => {
          this.selectedServiceOrder = result;
          this.gopPreviewedServiceOrderInitial = this.stringifyAndSanitizeServiceOrder(this.selectedServiceOrder);
          this.selectedServiceOrderInitialStatus = this.selectedServiceOrder.status;
          this.serviceOrderService.previewGop(this.selectedServiceOrder).subscribe(data => {
            this.serviceOrderTypeState.gopHasBeenPreviewed = true;
            const file = new Blob([data], { type: 'application/pdf' });
            const fileURL = URL.createObjectURL(file);
            this.dialogViewerService.openPreviewDocumentDialog(this.serviceOrderId, fileURL, this.caseId, this.caseNumber);
            this.editModal = true;
            this.serviceOrderTypeState.gopPreviewWasClicked = true;
          });
        });
      }
    });

    this.$sendGopEventSubscr = this.serviceOrderTypeState.sendGopEvent.subscribe(e => {
      // save document
      this.serviceOrderService.editServiceOrder(this.selectedServiceOrder, true).subscribe(
        _ => {
          this.createGop();
        },
        error => {
          this.serviceOrderTypeState.sendingGopInProgress = false;
          throw error;
        }
      );
    });

    this.$createPreAssessmentGopSubscr = this.serviceOrderTypeState.medicalPreAssessmentGopEvent.subscribe(e => {
      if (this.selectedServiceOrder.serviceOrderId == null) {
        this.serviceOrderService.createServiceOrder(this.selectedServiceOrder).subscribe(serviceeOrdr => {
          this.serviceOrderId = serviceeOrdr.id;
          this.serviceOrderService.previewGop(this.selectedServiceOrder).subscribe(data => {
            this.serviceOrderTypeState.gopHasBeenPreviewed = true;
            const file = new Blob([data], { type: 'application/pdf' });
            const fileURL = URL.createObjectURL(file);
            this.dialogViewerService.openPreviewDocumentDialog(this.serviceOrderId, fileURL, this.caseId, this.caseNumber);
            this.editModal = true;
            this.serviceOrderTypeState.gopPreviewWasClicked = true;
          });
        });
      }

    });

    this.$sendConfirmEventSubscr = this.serviceOrderTypeState.sendConfirmationEvent.subscribe(e => {
      this.editModal = this.selectedServiceOrder.serviceOrderId != null;
      this.saveServiceOrder(true);
    });
  }

  createGop() {
    this.serviceOrderService.createGop(this.selectedServiceOrder).subscribe(
      documentId => {
        const supplier = this.returnedProviders.find(s => +s.id == this.selectedServiceOrder.supplierId);
        const ccSupplier = this.returnedProviders.find(s => +s.id == this.selectedServiceOrder.supplierId);
        const message = new Message();
        message.caseId = this.caseId;
        message.caseNumber = '[' + this.caseNumber + '-' + this.selectedServiceOrder.serviceOrderId + ']';
        message.stakeholderReceiverId = this.selectedServiceOrder.supplierId;
        message.receiver = lodash.cloneDeep(
          this.returnedProviders.find(stakeholder => +stakeholder.id === this.selectedServiceOrder.supplierId)
        );

        if (this.supplier.company.gop.preferredChannelCode?.toUpperCase() === MessageChannelType.FAX) {
          message.messageChannelType = MessageChannelType.FAX;
          const faxData: PhoneNumber = this.supplier.company.gop.phoneNumbers.find(phoneNumber => phoneNumber.type === PhoneNumberType.Fax);
          if (faxData != null && message.fax == null) {
            message.fax = new FaxDto();
          }
        } else {
          message.messageChannelType = MessageChannelType.EMAIL;
        }

        message.channelDisabled = true;
        if (this.selectedServiceOrder.type === ServiceTypeEnum.TREATMENT ||
          this.selectedServiceOrder.type === ServiceTypeEnum.ACCOMMODATION ||
          this.selectedServiceOrder.type === ServiceTypeEnum.REGULAR_FLIGHT_MEDICAL) {
          message.gopContactPersonShouldBeChosen = true;
        }
        if (this.selectedServiceOrder.type === ServiceTypeEnum.REGULAR_FLIGHT_MEDICAL) {
          message.receiverIsNotChangable = true;
        }
        if (
          supplier.company != null &&
          supplier.company.gop != null
        ) {
          message.cc = [];
          if (supplier.company.gop.method === GopMethodEnum.COPY_AGENT_OFFICE) {
            if (supplier.company.gop.preferredChannelCode == PreferredChannelEnum.FAX) {
              message.messageChannelType = MessageChannelType.FAX;
              const faxPhone = supplier.company.gop.phoneNumbers.find(p => p.type === 'FAX');
              message.fax = new FaxDto({ countryCode: faxPhone.countryCode, faxNumber: faxPhone.number, faxPrefix: faxPhone.prefix });
            }
            message.stakeholderReceiverId = this.selectedServiceOrder.supplierId;
            const agentCc = this.returnedProviders.find(s => +s.id === supplier.company.gop.agentId);
            message.cc.push(agentCc);
          } else if (supplier.company.gop.method === GopMethodEnum.VIA_AGENT_OFFICE) {
            const agent = this.returnedProviders.find(s => +s.id === supplier.company.gop.agentId);
            if (agent.company.gop.preferredChannelCode == PreferredChannelEnum.EMAIL) {
              message.email = agent.email;
            }
            if (agent.company.gop.preferredChannelCode == PreferredChannelEnum.FAX) {
              message.messageChannelType = MessageChannelType.FAX;
              const faxPhone = agent.company.gop.phoneNumbers.find(p => p.type === PhoneNumberType.Fax);
              message.fax = new FaxDto({ countryCode: faxPhone.countryCode, faxNumber: faxPhone.number, faxPrefix: faxPhone.prefix });
            }
            message.receiver = agent;
            message.stakeholderReceiverId = +agent.id;

            message.gopContactPersonShouldBeChosen = false;
          } else if (supplier.company.gop.method === GopMethodEnum.DIRECT_PROVIDER) {
            if (supplier.company.gop.preferredChannelCode == PreferredChannelEnum.FAX) {
              message.messageChannelType = MessageChannelType.FAX;
              const faxPhone = supplier.company.gop.phoneNumbers.find(p => p.type === PhoneNumberType.Fax);
              message.fax = new FaxDto({ countryCode: faxPhone.countryCode, faxNumber: faxPhone.number, faxPrefix: faxPhone.prefix });
            }
          }
          supplier.company.contactPersons.forEach(contact => {
            if (contact.propertyTypes.some(propertyType => this.ccContactTypes.some(businessKey => businessKey === propertyType.businessKey))){
              const newStakeholder = _.cloneDeep(supplier);
              newStakeholder.company.name = supplier.company.name + ' (' + contact.firstName + ' ' + contact.surname + ')';
              newStakeholder.company.contactPersons = [];
              newStakeholder.company.email = contact.email;
              message.cc.push(newStakeholder);
            }
          });
        }
        if (message.stakeholderReceiverId == null) {
          message.stakeholderReceiverId = this.selectedServiceOrder.supplierId;
        }

        if (documentId) {
          message.preSelectDocumentAttachments = [new AttachmentDto({
            documentId,
            attachmentType: AttachmentTypeEnum.GENERATED_FROM_TEMPLATE
          })];
        }

        // Wait a little for the service order dialog to close before opening the outputmanagement dialog
        setTimeout(() => {
          this.outputManagementService.openMessageWindow(
            this.caseId,
            new ServiceOrderMessageRequest({
              serviceOrder: this.selectedServiceOrder,
              message,
              messageService: this.messageService
            })
          );
        }, 500);

        this.serviceOrderTypeState.sendingGopInProgress = false;
        this.close();
      },
      error => {
        this.serviceOrderTypeState.sendingGopInProgress = false;
        throw error;
      }
    );
  }

  setSelectedType(serviceType: string) {
    this.disableSaveButtonStateChanges();
    this.resetSelectedServiceOrder();
    this.selectedServiceOrderType = this.findServiceOrderType(serviceType);
    this.selectedServiceOrder.type = ServiceTypeEnum[serviceType];
    if (
      [ServiceTypeEnum.ADVISE_ON_INSURANCE_TERMS,
        ServiceTypeEnum.GENERAL_ADVICE,
        ServiceTypeEnum.ADVICE_CALL_REGISTRATION,
        ServiceTypeEnum.REFERRAL_TO_CUSTOMER,
        ServiceTypeEnum.MEDICAL_PRE_ASSESSMENT,
        ServiceTypeEnum.PRE_DEPARTURE_TRAVEL_SERVICE,
        ServiceTypeEnum.MEDICAL_FLIGHT_AUTHORIZATION
      ].includes(this.selectedServiceOrder.type)
    ) {
      this.selectedServiceOrder.status = StatusTypes.COMMITTED;
    }
    this.selectedServiceOrder.extension = this.serviceOrderService.extensionFactory(this.selectedServiceOrderType);
    this.selectedServiceOrder.expectedCostCurrency = this.defaultBillingCurrency;
    if (this.selectedServiceOrder.type === ServiceTypeEnum.MEDICAL_ASSESSMENT && !this.editModal) {
      this.selectedServiceOrder.caseStakeholderIds = [];
      this.selectedServiceOrder.extension = new MedicalAssessmentServiceOrderExtension({ isDescriptionRequired: !this.isCaseTypeMedicalPreAssesssment() });
      this.selectedServiceOrder.extension.setCommitmentType(this.selectedServiceOrderType.defaultCommitmentType);
    }
    if ([ServiceTypeEnum.REGULAR_FLIGHT_NON_MEDICAL, ServiceTypeEnum.REGULAR_FLIGHT_MEDICAL].includes(this.selectedServiceOrder.type) && !this.egenciaStakeholderId) {
      this.showProviderSpinner = true;
      const searchQuery = this.supplierSearchHelper.generateSearchQuery({ freeText: `supplierProfileCode:${this.SUPPLIER_PROFILE_CODE_EGENCIA}` });
      this.egenciaStakeholderId
      this.extracted(searchQuery, this.SUPPLIER_PROFILE_CODE_EGENCIA);
    } else
      if ([ServiceTypeEnum.GROUND_TRANSPORT].includes(this.selectedServiceOrder.type) && !this.getEInternationalStakeholderId) {
      this.showProviderSpinner = true;
        const searchQuery = this.supplierSearchHelper.generateSearchQuery({ freeText: `supplierProfileCode:${this.SUPPLIER_PROFILE_CODE_GETE}` });
        this.extracted(searchQuery, this.SUPPLIER_PROFILE_CODE_GETE);
    } else
      if ([ServiceTypeEnum.HANDLING_OF_MORTAL_REMAINS].includes(this.selectedServiceOrder.type) && !this.albinStakeholderId) {
      this.showProviderSpinner = true;
      const searchQuery = this.supplierSearchHelper.generateSearchQuery({ freeText: `supplierProfileCode:${this.SUPPLIER_PROFILE_CODE_ALBIN}`});
      this.extracted(searchQuery, this.SUPPLIER_PROFILE_CODE_ALBIN);
    } else {
      this.refreshComponentsInNextAngularCycle();
    }
    this.enableSaveButtonStateChanges();
  }

  private extracted(searchQuery: string, SUPPLIER_PROFILE_CODE: string) {
    this.supplierService.searchSuppliers(searchQuery).pipe(
      map((result: SupplierSearchResponse) =>
        result.docList.filter(supplier =>
          supplier.supplierProfileCode === SUPPLIER_PROFILE_CODE &&
          supplier.aooType.toUpperCase() === this.AOO_TYPE
        )
      ),
      filter(suppliers => suppliers.length > 0),
      map(suppliers => this.supplierSearchHelper.mapDocsToProviders(suppliers, +this.caseId, this.loggedInUser, this.contactRoles, 'preselected supplier')),
      switchMap(providerLookup => {
        return this.supplierService.addSupplierToCase(providerLookup).pipe(
          tap(number => {
            switch (SUPPLIER_PROFILE_CODE) {
              case this.SUPPLIER_PROFILE_CODE_EGENCIA:
                this.egenciaStakeholderId = +number;
                break;
              case this.SUPPLIER_PROFILE_CODE_GETE:
                this.getEInternationalStakeholderId = +number;
                break;
              case this.SUPPLIER_PROFILE_CODE_ALBIN:
                this.albinStakeholderId = +number;
                break;
            }
          })
        );
      }),
      catchError(error => {
        console.error('Error occurred on sending supplier to backend:', error);
        return EMPTY;
      }),
      finalize(() => {
        this.showProviderSpinner = false;
        this.supplierAdded();
      })
    ).subscribe({
      next: () => {},
      error: (err) => console.error('Subscription error:', err),
      complete: () => {
      }
    });
  }

  findServiceOrderType(serviceType: string): any {
    return this.availableServiceOrderTypes.find((obj) => {
      return obj.serviceTypeEnumValue === serviceType;
    });
  }

  refreshComponentsInNextAngularCycle() {
    const source = timer(100);
    this.$timerSubscr = source.subscribe(() => {
      this.selectedServiceTypeComponent = this.serviceTypeComponent;
    });
  }

  loadStakeholders() {
    this.caseService.getCaseStakeholdersOnCase(this.caseId.toString()).subscribe(result => {
      this.returnedProviders = result;
      const stakeholders = result.filter(
        stakeholder =>
          stakeholder.hasRole(StakeholderTypeEnum.escortDoctor) ||
          stakeholder.hasRole(StakeholderTypeEnum.escortNurse) ||
          stakeholder.hasRole(StakeholderTypeEnum.endUser) ||
          stakeholder.hasRole(StakeholderTypeEnum.person) ||
          stakeholder.hasRole(StakeholderTypeEnum.policyHolder) ||
          stakeholder.hasRole(StakeholderTypeEnum.reporter)
      );

      stakeholders.sort(
        (n1, n2) =>
          StakeholderTypeOrder.getStakeholderTypeOrder(n1.stakeholderType) -
          StakeholderTypeOrder.getStakeholderTypeOrder(n2.stakeholderType)
      );
      this.stakeholders = stakeholders;
      this.showProviderSpinner = false;
      this.checkSupplierAndAgentDetails(this.selectedServiceOrder?.supplierId);
    });
  }

  supplierAdded() {
    this.loadStakeholders();
  }

  supplierChange(supplierId: number) {
    this.checkSupplierAndAgentDetails(supplierId);
  }

  private checkSupplierAndAgentDetails(supplierId?: number) {
    this.serviceOrderTypeState.stakeholderEmailRequiredAndMissing = false;
    this.serviceOrderTypeState.stakeholderEmailInvalid = false;
    this.serviceOrderTypeState.supplierGopEmailRequiredAndMissing = false;
    this.serviceOrderTypeState.supplierGopFaxRequiredAndMissing = false;
    this.serviceOrderTypeState.supplierGopEmailInvalid = false;
    this.serviceOrderTypeState.supplierRequiredAndMissing = false;
    this.serviceOrderTypeState.supplierGopMethodIsMissing = false;
    this.serviceOrderTypeState.agentMissing = false;
    this.serviceOrderTypeState.agentGopEmailRequiredAndMissing = false;
    this.serviceOrderTypeState.agentGopFaxRequiredAndMissing = false;
    this.serviceOrderTypeState.agentGopEmailInvalid = false;

    if (!supplierId) {
      this.serviceOrderTypeState.supplierRequiredAndMissing = true;
      return;
    }

    if (!this.returnedProviders) {
      this.serviceOrderTypeState.stakeholderEmailRequiredAndMissing = true;
      return;
    }

    const supplier = this.returnedProviders.find(s => +s.id == supplierId);
    this.supplier = supplier;
    let agent;
    if (supplier != null && supplier.company != null && supplier.company.gop != null) {
      agent = this.returnedProviders.find(s => +s.id == supplier.company.gop.agentId);
    }

    switch (this.selectedServiceOrder?.type) {
      case ServiceTypeEnum.REGULAR_FLIGHT_MEDICAL:
        if (!supplier?.email) {
          this.serviceOrderTypeState.stakeholderEmailRequiredAndMissing = true;
        } else if (this.inputValidationService.isValidated(supplier.email, ValidationTypeEnum.email)) {
          this.serviceOrderTypeState.stakeholderEmailInvalid = true;
        }
        break;

      default:
        switch (supplier?.company?.gop?.method) {
          case GopMethodEnum.COPY_AGENT_OFFICE:
            const isValid = this.checkSupplierGopDetails(supplier);
            if (isValid) {
              this.checkAgentGopDetails(supplier, agent);
            }
            break;

          case GopMethodEnum.VIA_AGENT_OFFICE:
            this.checkAgentGopDetails(supplier, agent);
            break;

          case GopMethodEnum.DIRECT_PROVIDER:
            this.checkSupplierGopDetails(supplier);
            break;

          default:
            this.serviceOrderTypeState.supplierGopMethodIsMissing = true;
        }
    }

    // Trigger change detection
    this.serviceOrderTypeState = lodash.clone(this.serviceOrderTypeState);
    this.caseStateService.updatedStakeholder(supplier);
  }

  private checkAgentGopDetails(supplier: CaseStakeholder, agent: CaseStakeholder): void {
    if (!supplier?.company?.gop?.agentId) {
      this.serviceOrderTypeState.agentMissing = true;
    } else {
      const validation = this.checkSupplierCompanyGopDetails(agent);
      if (validation.emailMissing) {
        this.serviceOrderTypeState.agentGopEmailRequiredAndMissing = true;
      }
      if (validation.emailInvalid) {
        this.serviceOrderTypeState.agentGopEmailInvalid = true;
      }
      if (validation.faxMissing) {
        this.serviceOrderTypeState.agentGopFaxRequiredAndMissing = true;
      }
    }
  }

  private checkSupplierGopDetails(supplier: CaseStakeholder): boolean {
    const validation = this.checkSupplierCompanyGopDetails(supplier);
    if (validation.emailMissing) {
      this.serviceOrderTypeState.supplierGopEmailRequiredAndMissing = true;
    }
    if (validation.emailInvalid) {
      this.serviceOrderTypeState.supplierGopEmailInvalid = true;
    }
    if (validation.faxMissing) {
      this.serviceOrderTypeState.supplierGopFaxRequiredAndMissing = true;
    }

    return !validation.contactInfoMissing;
  }

  private checkSupplierCompanyGopDetails(supplier: CaseStakeholder): any {
    let emailMissing = false;
    let faxMissing = false;
    let emailInvalid = false;
    switch (supplier?.company?.gop?.preferredChannelCode) {
      case PreferredChannelEnum.FAX:
        const faxPhone = supplier?.company?.gop?.phoneNumbers?.find(p => p.type === 'FAX');
        if (!faxPhone || !faxPhone.countryCode || !faxPhone.prefix || !faxPhone.number) {
          faxMissing = true;
        }
        break;

      default:
        if (!supplier?.company?.gop?.email) {
          emailMissing = true;
        } else if (this.inputValidationService.isValidated(supplier.company.gop.email, ValidationTypeEnum.email)) {
          emailInvalid = true;
        }
        break;
    }

    return { emailMissing, emailInvalid, faxMissing };
  }

  close() {
    if (this.egenciaStakeholderId != null || this.getEInternationalStakeholderId != null || this.albinStakeholderId != null) {

      combineLatest([
        this.caseService.getCaseStakeholdersOnCase(this.caseId),
        this.serviceOrderService.getServiceOrdersForCase(+this.caseId)
      ]).subscribe(res => {
        const stakeholders = res[0];
        const serviceOrders = res[1];

        if (this.egenciaStakeholderId != null) {
          const egenciaStakeholder = stakeholders.find(item => +item.id === this.egenciaStakeholderId);
          const otherTravelAgency = stakeholders.find(item => item.stakeholderType === StakeholderTypeEnum.travelAgency && +item.id !== this.egenciaStakeholderId);

          if (egenciaStakeholder) {
            if (otherTravelAgency || !serviceOrders.some(serviceOrder => serviceOrder.type === ServiceTypeEnum.REGULAR_FLIGHT_MEDICAL || serviceOrder.type === ServiceTypeEnum.REGULAR_FLIGHT_NON_MEDICAL)) {
              this.caseService.isStakeholderDeletable(this.caseId, egenciaStakeholder.id).subscribe(
                isDeletable => {
                  if (isDeletable) {
                    this.caseService.deleteStakeholderOnCase(this.caseId, egenciaStakeholder.id).subscribe(value => {
                    });
                  }
                }
              );
            }
          }
        }
        if (this.getEInternationalStakeholderId != null) {
          const getEStakeholder = stakeholders.find(item => +item.id === this.getEInternationalStakeholderId);
          const otherTravelAgency = stakeholders.find(item => item.stakeholderType === StakeholderTypeEnum.travelAgency && +item.id !== this.getEInternationalStakeholderId);

          if (getEStakeholder) {
            if (otherTravelAgency || !serviceOrders.some(serviceOrder => serviceOrder.type === ServiceTypeEnum.GROUND_TRANSPORT)) {
              this.caseService.isStakeholderDeletable(this.caseId, getEStakeholder.id).subscribe(
                isDeletable => {
                  if (isDeletable) {
                    this.caseService.deleteStakeholderOnCase(this.caseId, getEStakeholder.id).subscribe(value => {
                    });
                  }
                }
              );
            }
          }
        }
        if (this.albinStakeholderId != null) {
          const albinStakeholder = stakeholders.find(item => +item.id === this.albinStakeholderId);
          const otherTravelAgency = stakeholders.find(item => item.stakeholderType === StakeholderTypeEnum.travelAgency && +item.id !== this.albinStakeholderId);

          if (albinStakeholder) {
            if (otherTravelAgency || !serviceOrders.some(serviceOrder => serviceOrder.type === ServiceTypeEnum.HANDLING_OF_MORTAL_REMAINS)) {
              this.caseService.isStakeholderDeletable(this.caseId, albinStakeholder.id).subscribe(
                isDeletable => {
                  if (isDeletable) {
                    this.caseService.deleteStakeholderOnCase(this.caseId, albinStakeholder.id).subscribe(value => {
                    });
                  }
                }
              );
            }
          }
        }

      });
    }
    this.closeEvent.emit();
  }

  submitCreate(sendConfirm?: boolean) {
    this.selectedServiceOrder.serviceOrderId = this.displayServiceOrderId;
    this.serviceOrderService.createServiceOrder(this.selectedServiceOrder).subscribe(
      result => {
        this.isValid = false;
        this.saveWorkPending = false;
        this.selectedServiceOrder = result;
        this.caseStateService.sendServiceOrderChange();
        if (sendConfirm) {
          this.sendConfirmation();
        } else {
          this.close();
        }
      },
      error => {
        this.saveWorkPending = false;
        throw error;
      }
    );
  }

  submitDelete() {
    this.serviceOrderService.markAsDeletedServiceOrder(this.selectedServiceOrder.id, this.selectedServiceOrder.caseId).subscribe(
      result => {
        this.saveWorkPending = false;
        this.selectedServiceOrder = result;
        this.caseStateService.sendServiceOrderChange();
        this.close();
      },
      error => {
        this.saveWorkPending = false;
        throw error;
      }
    );
  }

  submitEdit(sendConfirm?: boolean) {
    this.$editSubscr = this.serviceOrderService.editServiceOrder(this.selectedServiceOrder).subscribe(
      _ => {
        this.isValid = false;
        this.saveWorkPending = false;
        this.caseStateService.sendServiceOrderChange();
        if (sendConfirm) {
          this.sendConfirmation();
        } else {
          if (!this.serviceOrderTypeState.sendRequestRequested) {
            this.close();
          }
        }
      },
      error => {
        console.log(error);
        this.saveWorkPending = false;
      }
    );
  }

  setReserveAmountIfNotProvided() {
    if (
      this.selectedServiceOrder.type === ServiceTypeEnum.ADVISE_ON_INSURANCE_TERMS &&
      isNaN(+this.selectedServiceOrder.expectedCostValue)
    ) {
      this.selectedServiceOrder.expectedCostValue = '0';
    }
  }

  setLuggageStatusToCommitted(): void {
    if (this.selectedServiceOrder.type === ServiceTypeEnum.LUGGAGE && ![StatusTypes.CANCELLED, StatusTypes.NOT_COVERED].includes(this.selectedServiceOrder.status)) {
      this.selectedServiceOrder.status = StatusTypes.COMMITTED;
    }
  }

  saveServiceOrder(sendConfirm?: boolean) {
    this.setReserveAmountIfNotProvided();
    this.setLuggageStatusToCommitted();
    this.selectedServiceOrder = this.serviceOrderModelMerger.merge(this.selectedServiceOrder, this.serviceTypeComponent.getModel());
    this.saveWorkPending = true;
    if (!this.editModal) {
      this.submitCreate(sendConfirm);
    } else {
      this.submitEdit(sendConfirm);
    }
  }

  private sendConfirmation() {
    this.outputManagementService.openMessageWindow(this.caseId, this.getMessageRequestForServiceOrder());
    if (this.selectedServiceOrder.type !== ServiceTypeEnum.AMBULANCE_FLIGHT && this.selectedServiceOrder.type !== ServiceTypeEnum.COORDINATION_TRANSPORT) {
      this.outputManagementService.selectedMessage.subscribe(message => {
        if (message.send && this.serviceOrderTypeState.sendRequestRequested === true) {
          this.setRequestSentAndSave();
        }
      });
    }
    this.close();
  }

  setRequestSentAndSave() {
    this.selectedServiceOrder.requestSent = true;
    this.serviceOrderTypeState.requestSent = true;
    this.selectedServiceOrder.status = StatusTypes.COMMITTED;
    this.editModal = true;
    this.saveServiceOrder(false);
  }

  async getServiceOrderTypes(caseId: string) {
    const [info, types] = await Promise.all([this.insuranceService.getCustomerProfileInfo(caseId).toPromise(),
      this.serviceOrderService.getServiceOrderTypesPerCase(caseId).toPromise()]);
    this.availableServiceOrderTypes = types;
    this.customerProfileInfo = info;
    await this.setServiceOrderTypes();
    if (this.serviceOrderId == null) {
      this.show();
    } else {
      this.showEdit(this.serviceOrderId);
    }
  }

  private async setServiceOrderTypes() {
    if (this.availableServiceOrderTypes) {
      this.types = this.availableServiceOrderTypes.map(serviceOrderType => this.serviceOrderService.mapMasterlistServiceTypeToServiceOrderTypeWithIcons(serviceOrderType, this.customerProfileInfo.claimsAgreement, this.customerProfileInfo.medicalFlightAuthorizationAgreement,  true,));
    }
  }

  getMessageRequestForServiceOrder(): ServiceOrderMessageRequest {
    const msg = new Message({
      caseNumber: '[' + this.caseNumber + '-' + this.selectedServiceOrder.serviceOrderId + ']'
    });

    switch (this.selectedServiceOrder.type) {
      case ServiceTypeEnum.REGULAR_FLIGHT_NON_MEDICAL:
        if (this.selectedServiceOrder.status === StatusTypes.EXPECTED) {
          msg.stakeholderReceiverId = this.selectedServiceOrder.supplierId;
        } else {
          const endUser = this.getEndUser();
          if (endUser !== null) {
            msg.stakeholderReceiverId = +endUser.id;
          }
        }
        msg.messageChannelType = MessageChannelType.EMAIL;
        break;
      case ServiceTypeEnum.REGULAR_FLIGHT_MEDICAL:
        const endUser = this.getEndUser();
        if (endUser !== null) {
          msg.stakeholderReceiverId = +endUser.id;
        }
        msg.messageChannelType = MessageChannelType.EMAIL;
        break;
      case ServiceTypeEnum.GROUND_TRANSPORT:
        msg.stakeholderReceiverId = this.selectedServiceOrder.supplierId;
        msg.messageChannelType = MessageChannelType.EMAIL;
        break;
      case ServiceTypeEnum.ASSISTANCE_OR_CONTACT:
        msg.stakeholderReceiverId = this.selectedServiceOrder.supplierId;
        msg.messageChannelType = MessageChannelType.EMAIL;
        msg.receiverIsNotChangable = true;
        break;
      case ServiceTypeEnum.GROUND_AMBULANCE:
        msg.stakeholderReceiverId = this.selectedServiceOrder.supplierId;
        msg.messageChannelType = MessageChannelType.EMAIL;
        break;
      case ServiceTypeEnum.HANDLING_OF_MORTAL_REMAINS:
        msg.stakeholderReceiverId = this.selectedServiceOrder.supplierId;
        msg.messageChannelType = MessageChannelType.EMAIL;
        break;
      case ServiceTypeEnum.AMBULANCE_FLIGHT:
        msg.requestConfirmNeededForServiceOrder = this.selectedServiceOrder;
        if (this.selectedServiceOrder.status === StatusTypes.EXPECTED) {
          msg.stakeholderReceiverId = this.selectedServiceOrder.supplierId;
        } else {
          const endUser = this.getEndUser();
          if (endUser !== null) {
            msg.stakeholderReceiverId = +endUser.id;
          }
        }
        msg.messageChannelType = MessageChannelType.EMAIL;
        break;
      case ServiceTypeEnum.COORDINATION_TRANSPORT:
        msg.requestConfirmNeededForServiceOrder = this.selectedServiceOrder;
        msg.stakeholderReceiverId = this.selectedServiceOrder.supplierId;
        msg.messageChannelType = MessageChannelType.EMAIL;
        break;
      default:
    }
    return new ServiceOrderMessageRequest({
      serviceOrder: this.selectedServiceOrder,
      message: msg,
      messageService: this.messageService
    });
  }

  getEndUser(): CaseStakeholder {
    return this.stakeholders.find(value => {
      return value.hasRole(StakeholderTypeEnum.endUser);
    });
  }

  getEndUserOrFirstStakeholder(cardStakeholders: SubStakeholder[]): CaseStakeholder {
    const stakeholder = this.stakeholders.find(value => {
      return (
        value.hasRole(StakeholderTypeEnum.endUser) && !!cardStakeholders.find(selected => selected.stakeholderId === value.id)
      );
    });

    return !!stakeholder ? stakeholder : this.stakeholders.find(value => value.id === cardStakeholders[0].stakeholderId);
  }

  get AutoCompleteTypeEnum() {
    return AutoCompleteTypeEnum;
  }

  get PermissionEnum() {
    return PermissionEnum;
  }

  disableSaveButton(): boolean {
    if (this.serviceTypeComponent == null || !this.isSaveButtonStateChangesEnabled) {
      return true;
    } else {
      if (this.iccServiceOrder) {
        return !this.hasModelChanged();
      } else {

      return !this.serviceOrderTypeState.isLeftSideValid || // false
        !this.serviceTypeComponent.isValid() || // false
        this.saveWorkPending ||
        !this.hasModelChanged();
      }
    }
  }

  get showDeleteButton(): boolean {
    if (this.selectedServiceOrder.type === ServiceTypeEnum.MEDICAL_ASSESSMENT) {
      return (
        this.selectedServiceOrder.serviceOrderId != null && (this.selectedServiceOrder.extension as MedicalAssessmentServiceOrderExtension).commitmentType &&
        (this.selectedServiceOrder.extension as MedicalAssessmentServiceOrderExtension).commitmentType.businessKey === CommitmentTypes.EXPECTED
      );
    } else {
      return this.selectedServiceOrder.serviceOrderId != null && this.selectedServiceOrderInitialStatus === StatusTypes.EXPECTED;
    }
  }

  markServiceOrderAsDeleted() {
    this.saveWorkPending = true;
    this.submitDelete();
  }

  showMissingSupplierPopOver(): boolean {
    return this.selectedServiceOrder.type === ServiceTypeEnum.AMBULANCE_FLIGHT && this.selectedServiceOrder.status === StatusTypes.COMMITTED && !this.supplier;
  }

  showMissingFieldPopOverFirstTime() {
    if(this.serviceTypeComponent) {
      setTimeout(() => {
        const result = this.showMissingFieldPopOver();
        this.setTimerUsed = true;
        return result;
      }, 500);
    }
  }

  generateMissingFieldsText() {
    if (this.serviceTypeComponent && 'getMissingFields' in this.serviceTypeComponent) {
        const missingFields = (this.serviceTypeComponent as unknown as ValidatingFieldsServiceTypeComponent).getMissingFields();
        let _hoverContent;
        _hoverContent = this.translateService.instant('service-order-missing-fields');
        missingFields?.forEach(mf => {
          if(mf.includes('relevantforcase')) {
            _hoverContent += '<br/>- ' + mf.split(' ')[1] + ' ' + this.translateService.instant('service-order-missing-field-relevantforcase');
          }
          else if(mf.includes('passengerConfirmed')) {
            _hoverContent += '<br/>- ' + mf.split(' ')[1] + ' ' + this.translateService.instant('service-order-missing-field-passengerConfirmed');
            }
          else {
            _hoverContent += '<br/>- ' + this.translateService.instant('service-order-missing-field-' + mf);
          }
        });
        if(this.selectedServiceOrder.status === StatusTypes.COMMITTED && !this.supplier) { _hoverContent += '<br/>- ' + this.translateService.instant('left-side-no-supplier-selected-warning') }
        this.hoverContent = _hoverContent;
    } else {
      this.hoverContent += this.translateService.instant('left-side-no-supplier-selected-warning')
    }

  }

  showMissingFieldPopOver(): boolean {
    if(this.serviceTypeComponent) {
      if ('getMissingFields' in this.serviceTypeComponent) {
          return (this.serviceTypeComponent as unknown as ValidatingFieldsServiceTypeComponent).getMissingFields().length !== 0 || (this.selectedServiceOrder.status === StatusTypes.COMMITTED && !this.supplier);
      } else {
        return this.selectedServiceOrder.type === ServiceTypeEnum.AMBULANCE_FLIGHT
          && this.selectedServiceOrder.status === StatusTypes.COMMITTED
          && !this.supplier;
      }
    }
  }

  /**
   * JON stringifies and sanitizes a serviceOrder: ServiceOrder structure.
   *
   * Converts 'expectedCostValue' to number when string value.
   * Exclude ui property 'stakeholderType'
   */
  private stringifyAndSanitizeServiceOrder(serviceOrder: ServiceOrder): string {
    return JSON.stringify(serviceOrder, (key, val) => {
      if (SubRepatriationFormMedical.jsonIgnoreFields.indexOf(key) !== -1) {
        return undefined;
      }
      if (key === 'expectedCostValue' && typeof val === 'string') {
        return +val;
      } else if (key !== 'caseStakeholderIds' && key !== 'stakeholderType') {
        return val;
      }
    });
  }

  private caseTypeUpdated(caseType: CaseType): void {
    this.caseType = caseType;
    this.setServiceOrderTypes();
  }

  private serviceOrderUpdated() {
    this.setServiceOrderTypes();
  }

  private disableSaveButtonStateChanges(): void {
    this.isSaveButtonStateChangesEnabled = false;
  }

  private enableSaveButtonStateChanges() {
    this.$stateChangetimerSubscr = timer(100).subscribe(() => {
      this.isSaveButtonStateChangesEnabled = true;
    });
  }

  private isCaseTypeMedicalPreAssesssment(): boolean {
    return CASE_TYPE_CODES.MEDICAL_PRE_ASSESSMENT === this.caseType?.code;
  }
}
