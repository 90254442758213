<div>
  <div class='d-flex background-colour'>
    <a href="#"></a>
    <div class='col-2 mx-0 mt-1 mb-2'>
      <div class='d-flex' [ngbPopover]='suppliername' triggers='hover' placement='top-start'>
        <img [src]='supplier.iconPath' alt='supplier pic' class='m-0'>
        <div>
          <div class='mx-2 mt-1 headline'>
            {{ supplier.supplierName | slice:0:30 }}{{ supplier.supplierName?.length > 30 ? '...' : '' }}
          </div>
          <div class='mx-2 smaller-text'>{{ supplier.supplierType }}</div>
        </div>
        <ng-template #suppliername>
          {{ supplier.supplierName }}
        </ng-template>
      </div>
      <div class='m-0 my-1'>
        <img alt='private hospital' class='pe-1' placement='top-start'
             [src]='getHospitalIconPrivate(supplier.ownership)'
             [ngbPopover]='iconTextPopOverPrivate'
             [autoClose]='false'
             triggers='hover'
             openDelay='400'
        />
        <ng-template #iconTextPopOverPrivate>
          {{ 'supplier-icon-private-hospital' | translate }}
        </ng-template>

        <label class='icon-treatment'>
          <img alt='public hospital' class='px-1' placement='top-start'
               [src]='getHospitalIconPublic(supplier.ownership)'
               [ngbPopover]='clickPublicCompanyPublic'
               triggers='hover'
               openDelay='400'
          />
        </label>
        <ng-template #clickPublicCompanyPublic>
          {{ 'supplier-icon-public-hospital' | translate }}
        </ng-template>

        <img alt='Avoid status' class='px-1' placement='top-start'
             [src]='getBlacklistFinancialMedicalScore(supplier.recommendations).iconPath'
             [ngbPopover]='getBlacklistFinancialMedicalScore(supplier.recommendations).tooltip'
             triggers='hover'
             openDelay='400'
        />
        <img alt='Supplier level' class='px-1' placement='top-start'
             [src]='getSupplierScore(supplier.recommendations).iconPath'
             [ngbPopover]='getSupplierScore(supplier.recommendations).tooltip'
             triggers='hover'
             openDelay='400'
        />
      </div>
    </div>

    <div class='col m-2'>
      <div>
        <div>
          <div>
            <div>
              {{ 'SUPPLIER_ALIASES' | translate }}
            </div>
            <div class='ms-2 mb-1'>
              {{ supplier.supplierAliases }}
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class='col m-2'>
      <div>
        <div>
          {{ 'SUPPLIER_REMARK' | translate }}
        </div>
        <div class=''>
          {{ supplier.remark }}
        </div>
      </div>
    </div>

    <div class='col m-2'>
      <div>
        <div>
          {{ 'SERVICE_TYPE_RECOMMENDATION' | translate }}
        </div>
        <div *ngIf='supplier.supplierService_list?.length'>
          <ul>
            <li *ngFor='let service of supplier.supplierService_list'>
              <div [class]='getRecommendationDecor(service.service_aoo_recommendation)'>
                {{ service.service_aoo_name }} - {{ service.service_aoo_recommendation }}
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>

    <div class='col-2 m-2'>
      <div>
        {{ 'SUPPLIER_HIGH_LIGHTED_COMPETENCES' | translate }}
      </div>
      <div class='mt-1'>
        <ul>
          <li *ngFor='let competence of supplier.highlightedCompetences'>{{ competence }}</li>
        </ul>
      </div>
      <div>
        {{ 'SUPPLIER_AOO_NAME' | translate }}
      </div>
      <div class='ms-2'>
        {{ supplier.aooName }}
      </div>
    </div>

    <div class='col-1 m-2'>
      <div>
        {{ 'SUPPLIER_RATING' | translate }}
      </div>
      <div class=''>
        {{ supplier.score | number:'1.2-2' }}
      </div>
      <div class='mt-1'>
        {{ 'SUPPLIER_DISTANCE' | translate }}
      </div>
      <div>
        <div *ngIf="supplier.aooCountryWide == 'Yes'">
          {{ ('SUPPLIER_COUNTRY_WIDE' | translate) + ' (' + (supplier.dist  | number:'1.2-2') + ' km)' }}
        </div>
        <div *ngIf="supplier.aooCountryWide == 'No'">
          {{ supplier.dist  | number:'1.2-2' }} km
        </div>
      </div>
    </div>

    <div class='d-flex flex-column'>
      <div class='m-2 d-flex flex-column align-items-end'>
        <button class='primary-button' [ngClass]="supplier.alreadySelected ? 'primary-button-inactive' : 'btn-info'" (click)='select(this)'
                [disabled]='supplier.alreadySelected' [appDisableInput]=callFS *ngIf='!callFS'>
          {{ 'SUPPLIER_SELECT' | translate }}
        </button>
        <div *ngIf='supplier.alreadySelected' class='text-danger'>
          {{ 'SUPPLIER_ALREADY_SELECTED' | translate }}
        </div>
        <div *ngIf='supplier.alreadyInCase' class='text-danger'>
          {{ 'SUPPLIER_ALREADY_IN_CASE' | translate }}
        </div>
      </div>
    </div>
  </div>

  <div class='mb-2'>
    <div class='d-flex justify-content-end alert-secondary p-2 background-colour-grayer' (click)='toggle()'>
      <img src='/assets/icons/ArrowDown.svg' [@dropdownMenuState]='isOpenState()' alt='drop down' class='m-2'>
    </div>

    <div class='d-flex alert-secondary mb-2 background-colour-grayer' *ngIf='isOpen()'>
      <div class='ms-2 col-5'>
        <div class='label-text'>{{ 'SUPPLIER_DESCRIPTION' | translate }}</div>
        <div class='my-2 me-2 p-2 inset-shadow'>
          <div>
            <div [innerHTML]='getDescriptionAsHtml(supplier.descriptionRichText )'></div>
          </div>
        </div>
      </div>

      <div class='col'>
        <div>
          <div class='label-text'>{{ 'SUPPLIER_GOP' | translate }}</div>
          <div class='p-2 mt-2 me-2 mb-2 inset-shadow'>
            <div>
              {{ 'SUPPLIER_GOP_METHOD' | translate }}
            </div>
            <div class='ms-2'>
              {{ supplier.gopMethod }}
            </div>
            <div>
              {{ 'SUPPLIER_GOP_REMARK' | translate }}
            </div>
            <div class='ms-2'>
              {{ supplier.gopRemark }}
            </div>
            <div>
            </div>
          </div>
        </div>
        <div>
          <div class='label-text'>{{ 'SUPPLIER_GOP_ASS_PARTNER' | translate }}</div>
          <div class='my-2 me-2 p-2 inset-shadow'>
            {{ supplier.partnerSupplier?.partner_name }}
          </div>
        </div>

        <div>
          <div class='label-text'>{{ 'SUPPLIER_AOO_COUNTRY' | translate }}</div>
          <div class='my-2 me-2 p-2 inset-shadow'>
            <div *ngIf='supplier.countries.length > amountOfCountriesToShow; else listCountries'>More than {{ amountOfCountriesToShow }}
              countries
            </div>
            <ng-template #listCountries>
              <ul class='list-inline'>
                <li *ngFor='let country of supplier.countries'>
                  {{ country }}
                </li>
              </ul>
            </ng-template>
          </div>
        </div>

        <div>
          <div class='label-text'>{{ 'SUPPLIER_MAIN_CONTACT' | translate }}</div>
          <div class='mt-2 me-2 p-2 inset-shadow'>
            <div class='row align-items-start'>
              <div class='col-3'>{{ 'SUPPLIER_ADDRESS' | translate }}</div>
              <div class='col'>{{ supplier.addressComposite }}</div>
            </div>
            <div class='row align-items-start'>
              <div class='col-3'>{{ 'SUPPLIER_PHONE' | translate }}</div>
              <div class='col'>{{ supplier.telephone }}</div>
            </div>
            <div class='row align-items-start'>
              <div class='col-3'>{{ 'SUPPLIER_FAX' | translate }}</div>
              <div class='col'> {{ supplier.fax }}</div>
            </div>
            <div class='row align-items-start'>
              <div class='col-3'>{{ 'SUPPLIER_EMAIL' | translate }}</div>
              <div class='col'>{{ supplier.email }}</div>
            </div>
            <div class='row'>
              <div class='col-3'>
                <img src='/assets/icons/globe2.svg' alt='Globe pic'>
              </div>
              <div class='col'>
                <a [href]=supplierSearchHelper.formatUrl(supplier.websiteUrl) target='_blank'>{{ supplier.websiteUrl }}</a>
              </div>
            </div>
            <div class='row'>
              <div class='col-2'>
                {{ 'SUPPLIER_PORTAL_URL' | translate }}
              </div>
              <div class='col'>
                <a [href]=supplierSearchHelper.formatUrl(supplier.portalUrl) target='_blank'>{{ supplier.portalUrl }}</a>
              </div>
            </div>
            <div class='row'>
              <div class='col-2'>
                {{ 'SUPPLIER_PORTAL_INFO' | translate }}
              </div>
              <div class='col'>
                {{ supplier.portalInfo }}
              </div>
            </div>
          </div>
        </div>

        <div>
          <div class=''>
            <div class='label-text'>{{ 'SUPPLIER_ID' | translate }}</div>
            <div class='mt-2 me-2 p-2 mb-2 inset-shadow'>
              {{ supplier.supplierId }}
            </div>
          </div>
        </div>
      </div>

      <div class='col'>
        <div class='label-text'>{{ 'SUPPLIER_CONTACTS' | translate }}</div>
        <div class='mt-2 me-2 p-2 mb-2 inset-shadow' *ngIf='supplier.contact_list?.length'>
          <ul>
            <li *ngFor='let contact_type of distinctContactTypes(supplier.contact_list)'>
              <div>{{ ('SUPPLIER_CONTACT_TYPE_PREFIX' | translate) + (contact_type != null ? contact_type.replace('Contact ', '') : ('SUPPLIER_CONTACT_TYPE_UNKNOWN' | translate)) }}</div>
              <ul>
                <li *ngFor='let contact of filterOnContact(supplier.contact_list, contact_type)'>
                  <div>{{ contact.contact_fullName }}</div>
                  <ul>
                    <div>{{ contact.contact_telephone }}</div>
                    <div>{{ contact.contact_email }}</div>
                    <div>{{ contact.contact_roleName }}</div>
                    <div *ngIf='contact.contact_roles?.length'>
                      {{ 'SUPPLIER_CONTACTS_ROLES' | translate }}
                    </div>
                    <div class='col ps-2'>
                      <ul>
                        <li *ngFor='let role of contact.contact_roles'>
                          <div>
                            {{ role.split(';').pop() }}
                          </div>
                        </li>
                      </ul>
                    </div>
                  </ul>
                </li>
              </ul>
            </li>
          </ul>
        </div>
        <div *ngIf='!supplier.contact_list?.length' class='mt-2 me-2 p-2 inset-shadow'>
        </div>

      </div>
      <div class='col me-2'>
        <div>
          <div class='label-text'>{{ 'SUPPLIER_COMPETENCIES' | translate }}</div>
          <div class='my-2 p-2 inset-shadow'>
            <ul>
              <li *ngFor='let competence of supplier.competences'>{{ competence }}</li>
            </ul>
          </div>
        </div>
      </div>
    </div>

  </div>
</div>
