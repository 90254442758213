import { Injectable } from '@angular/core';
import {
  SeccaSearchParams,
  SeccaSearchParamString
} from '@secca/case/components/case-plans/case-plans/add-service-order/search-service-provider/search-service-provider-modal/seccaSearchParams';
import { ContactItem, DocListItem, FacetPair} from '@secca/shared/models/SupplierSearchResponse';
import { Element, SupplierServicesResponse } from '@secca/shared/models/SupplierServicesResponse';
import { Contact, ProviderLookup } from '@secca/shared/models/SupplierLookup';
import { lowerCase, upperCase } from 'lodash';
import { SearchKeyword } from '@secca/case/components/case-plans/case-plans/add-service-order/search-service-provider/model/searchKeyword';
import {
  GopMethodEnumSearchSupplier,
  PhoneNumberType,
  PreferredChannel,
  ProviderOwnershipEnum,
  StakeholderTypeEnum
} from '@secca/shared/models/enums';
import { Language } from '@secca/shared/models/language';
import { PhoneNumber } from '@secca/shared/models/phoneNumber';
import { ContactPerson } from '@secca/shared/models/contactPerson';
import { PropertyTypeDb } from '@secca/shared/models/PropertyType';
import { UserDto } from '@secca/shared/models/userDto';
import { StakeholderService } from '@secca/core/services/stakeholder.service';

@Injectable({
  providedIn: 'root'
})
export class SupplierSearchHelper {

  constructor(private stakeholderService: StakeholderService) {
  }

  private firstPlusSupplierLevel: Set<string> = new Set(['1st Preferred Plus']);
  private firstSupplierLevel: Set<string> = new Set(['1st Preferred']);
  private secondSupplierLevel: Set<string> = new Set(['2nd Preferred']);
  BLACKLISTED: string = 'Blacklisted';
  AVOID_FINANCIAL: string = 'Avoid Financial';
  AVOID_MEDICAL: string = 'Avoid Medical';

  generateSearchQuery(searchParams: SeccaSearchParams): string {
    let query = '';
    query = this.appendQuery(query, this.toQueryOn('rows', searchParams.rows || 10));

    if (searchParams.freeText) {
      query = this.appendQuery(query, this.toQueryOn(SeccaSearchParamString.freeText, searchParams.freeText));
    }

    if (searchParams.location && searchParams.location.get('latitude') != null && searchParams.location.get('longitude') != null) {
      query = this.appendQuery(query, this.toQueryOnLocation(SeccaSearchParamString.location, searchParams.location));
    }

    if (searchParams.distance) {
      query = this.appendQuery(query, this.toQueryOn(SeccaSearchParamString.distance, searchParams.distance));
    }

    if (searchParams.serviceTypes) {
      query = this.appendQuery(query, this.toQueryOnFilters(SeccaSearchParamString.serviceTypes, searchParams.serviceTypes));
    }

    if (searchParams.supplierServices) {
      query = this.appendQuery(query, this.toQueryOnFilters(SeccaSearchParamString.supplierServices, searchParams.supplierServices));
    }

    if (searchParams.competences) {
      query = this.appendQuery(query, this.toQueryOnFilters(SeccaSearchParamString.competences, searchParams.competences));
    }

    if (searchParams.supplierType) {
      query = this.appendQuery(query, this.toQueryOnFilters(SeccaSearchParamString.supplierType, searchParams.supplierType));
    }

    if (searchParams.recommendations) {
      query = this.appendQuery(query, this.toQueryOnFilters(SeccaSearchParamString.recommendations, searchParams.recommendations));
    }

    if (searchParams.countries && !searchParams.countries.includes("undefined")) {
      query = this.appendQuery(query, this.toQueryOnFilters(SeccaSearchParamString.countries, searchParams.countries));
    }

    if (searchParams.distanceWeight >= 0) {
      query = this.appendQuery(query, this.toQueryOnFilters(SeccaSearchParamString.distanceWeight, [searchParams.distanceWeight]));
    }
    return query;
  }

  appendQuery(query: string, param: string): string {
    if (param) {
      return query ? `${query}&${param}` : param;
    }
    return query;
  }

  public toQueryOnFilters(key: string, values?: string[] | number[]): string {
    if (!values || values.length === 0) {
      return '';
    }
    return values.map(value => `${encodeURIComponent(key)}=${encodeURIComponent(value)}`).join('&');
  }

  toQueryOn(key: string, value: string | number): string {
    return encodeURI(key + '=' + value);
  }

  toQueryOnLocation(key: string, geoLocation: Map<string, number>) {
    return encodeURI(key + '=' + geoLocation.get('latitude') + ',' + geoLocation.get('longitude'));
  }

  formatUrl(url: string): string {
    if (!url) {
      return '';
    }
    // Check if the url starts with 'http://' or 'https://'
    if (url.startsWith('http://') || url.startsWith('https://')) {
      return url;
    } else {
      // Add 'https://' to the url
      return `https://${url}`;
    }
  }


  filterServiceDataElementsBasedOnServiceTypeCode(response: SupplierServicesResponse, serviceTypeCode: string): Element [] {
    return response.payload?.elements.filter(element => element.serviceOrderTypeData.sosBk === serviceTypeCode);
  }

  filterSupplierServicesBasedOnServiceName(serviceTypeNotUsedInSearch: boolean, supplierServicesFull: FacetPair[], supplierServiceListFiltered: Element[]): FacetPair[] {
    if (serviceTypeNotUsedInSearch || supplierServiceListFiltered == undefined) { //if a service type is chosen, filter the supplier services based on the service type
      return supplierServicesFull;
    }
    return supplierServicesFull?.filter(supplierService => supplierServiceListFiltered.some(supplierServiceFiltered => supplierService.first === supplierServiceFiltered.serviceData.name));
  }

  filterCompetenciesOnServiceTypes(competencies: SupplierServicesResponse, serviceTypeCodes: string[]): Element [] {
    return competencies.payload?.elements.filter(competence => serviceTypeCodes.includes(competence.serviceData?.sosBk));
  }

  filterCompetencies(serviceTypeNotUsedInSearch: boolean, competencesListFull: FacetPair[], competenciesListFiltered: Element[]): FacetPair[] {
    if (serviceTypeNotUsedInSearch || competenciesListFiltered == undefined) { //if a service type is chosen, filter the competencies based on the supplier services (filterCompetenciesOnServiceTypes)
      return competencesListFull;
    }
    return competencesListFull.filter(competence => competenciesListFiltered.some(competenceFiltered => competence.first === competenceFiltered.compentenceData?.name));
  }

  markSuppliersAsBlacklisted(filteredSuppliers: DocListItem[]) {
    filteredSuppliers.forEach(supplier => {
      supplier.blacklisted = supplier.recommendations?.some(recommendation => recommendation === this.BLACKLISTED);
    });
  }

  setIconSupplierLevel(recommendations: string[] = []): { iconPath: string, tooltip: string } {
    for (let recommendation of recommendations) {
      if (this.firstPlusSupplierLevel.has(recommendation)) {
        return { iconPath: '/assets/icons/provider/Gold-supplier.svg', tooltip: '1st preferred plus' };
      }
      if (this.firstSupplierLevel.has(recommendation)) {
        return { iconPath: '/assets/icons/provider/Gold-supplier.svg', tooltip: '1st preferred' };
      }
      if (this.secondSupplierLevel.has(recommendation)) {
        return { iconPath: '/assets/icons/provider/Silver-supplier.svg', tooltip: '2nd preferred' };
      }
    }
    return { iconPath: '/assets/icons/provider/Disabled-supplier.svg', tooltip: 'Supplier is not 1st or 2nd preferred' };
  }

  setIconFinancialMedicalOrBlackListed(recommendations: string[] = []): { iconPath: string, tooltip: string } {
    if (recommendations.includes(this.BLACKLISTED)) {
      return { iconPath: '/assets/icons/provider/hosp_blacklist_true_24x24.svg', tooltip: 'Blacklisted' };
    }
    if (recommendations.includes(this.AVOID_FINANCIAL) && recommendations.includes(this.AVOID_MEDICAL)) {
      return {
        iconPath: '/assets/icons/task/small/task-auto-todo-24x24.svg',
        tooltip: 'Avoid Financial & Medical'
      };
    }
    for (let recommendation of recommendations) {
      if (recommendation === this.AVOID_FINANCIAL) {
        return { iconPath: '/assets/icons/task/small/task-auto-todo-24x24.svg', tooltip: 'Avoid Financial' };
      }
      if (recommendation === this.AVOID_MEDICAL) {
        return { iconPath: '/assets/icons/task/small/task-auto-todo-24x24.svg', tooltip: 'Avoid Medical' };
      }
    }
    return { iconPath: '/assets/icons/provider/hosp_blacklist_false_24x24.svg', tooltip: 'Supplier is not blacklisted' };

  }

  getRecommendationDecor(recommendation: string): string {
    if (this.firstPlusSupplierLevel.has(recommendation) || this.firstSupplierLevel.has(recommendation) || this.secondSupplierLevel.has(recommendation)) {
      return 'text-decoration-underline fst-italic';
    }
    return '';
  }

  public extractGopContact(contactList: ContactItem[], roleCode: string): ContactItem {
    return contactList?.find(contact =>
      contact.contact_roles && contact.contact_roles.some(role => role.includes(roleCode)));
  }

  extractContactNotPrimaryAndAutoImportEnabled(contact_list: ContactItem[] = [], primaryRoleCode: string, autoImportFromSearchRoles: string[]): ContactItem[] {
    return contact_list.filter(contact => contact.contact_roles
      && contact.contact_roles.some(role => autoImportFromSearchRoles.includes(this.extractFromList(role, ';', 0))))
      .filter(contact => !contact.contact_roles.some(role => role.includes(primaryRoleCode)));
  }

  public convertToContactList(contact: ContactItem): Contact[] {
    let contactDetails: Contact[] = [];
    if (contact.contact_telephone) {
      contactDetails.push({
        comment: 'Telephone number of the contact',
        type: 'GOP_PHONE',
        value: contact.contact_telephone
      });
    }

    if (contact.contact_fax) {
      contactDetails.push({
        comment: 'Fax number of the contact',
        type: 'GOP_FAX',
        value: contact.contact_fax
      });
    }

    if (contact.contact_email) {
      contactDetails.push({
        comment: 'Email address of the contact',
        type: 'GOP_EMAIL',
        value: contact.contact_email
      });
    }

    return contactDetails;
  }

  getEnumKeyByEnumValue(myEnum: any, enumValue: string): string | null {
    let keys = Object.keys(myEnum).filter(x => upperCase(myEnum[x]) == upperCase(enumValue));
    return keys.length > 0 ? keys[0] : null;
  }

  getEnumValueByEnumKey(myEnum: any, enumKey: string): string {
    return myEnum[enumKey];
  }

  extractFromList(list: string, separator: string, entry: number): string {
    let entries = list.split(separator);
    return entries[entry];
  }

  differencesBetweenTwoArrays(array1: SearchKeyword[], array2: SearchKeyword[]): SearchKeyword[] {
    if (array1.length === 0) {
      return array2;
    }
    return array1.filter(keyword => !array2.some(keyword2 => keyword2.text === keyword.text && keyword2.category === keyword.category && keyword2.userEntered === keyword.userEntered));
  }

  mapDocsToProviders(docs: DocListItem[], caseId: number, loggedInUser: UserDto, contactRoles: PropertyTypeDb[], formattedSearchString: string): ProviderLookup {
    const providers = docs.map(doc => {
      const geoCoords = doc.geoCoord.split(',');
      const primaryContactRole = contactRoles?.find(role => role.gopPrimary === true)?.businessKey;
      const gopPrimaryContact: ContactItem = this.extractGopContact(doc.contact_list, primaryContactRole) || {} as ContactItem;
      console.log('gopPrimaryContact', gopPrimaryContact);
      const autoImportFromSearch = contactRoles?.filter(role => true === role.autoImportFromSearch).map(role => role.businessKey);
      console.log('autoImportFromSearch', autoImportFromSearch);
      const contactsNotPrimaryAndAutoImport: ContactItem[] = autoImportFromSearch ? this.extractContactNotPrimaryAndAutoImportEnabled(doc.contact_list, primaryContactRole, autoImportFromSearch) : [] as ContactItem[];
      console.log('contactsNotPrimaryAndAutoImport', contactsNotPrimaryAndAutoImport);

      return {
        providerId: '' + doc.supplierId,
        providerName: doc.supplierName,
        providerAliases: doc.supplierAliases,
        creditorNumber: doc.supplierName,
        primaryProviderTypeName: '',
        primaryProviderType: this.getEnumValueByEnumKey(StakeholderTypeEnum, doc.supplierType),
        primaryProviderTypeId: +doc.supplierTypeCode,
        providerRemarks: doc.remark,
        quality: '',
        ownership: this.getEnumKeyByEnumValue(ProviderOwnershipEnum, doc.ownership),
        blacklisted: doc.blacklisted,
        address: doc.addressLine1,
        zipCode: doc.zipCode || '',
        city: doc.city,
        aooCountryWide: doc.aooCountryWide,
        country: doc.addrCountry,
        countryCode: doc.addrCountryAlpha2,
        finance: '',
        contacts: doc.contact_list?.map(contact => {
          return {
            comment: contact.contact_remark,
            type: contact.contact_type,
            value: contact.contact_telephone
          };
        }) ?? [],
        financeContacts: [],
        gopPreferredChannel: gopPrimaryContact?.contact_preferredContactMethod,
        gopMethodDescription: doc.gopMethod,
        gopPreferredChannelDescription: '',
        gopCommunicationLanguageName: gopPrimaryContact?.contact_language,
        gopCommunicationLanguageCode: lowerCase(this.stakeholderService.getPreferredLanguages().find((a: Language) => a.language === gopPrimaryContact?.contact_language)?.code),
        gopMethod: this.getEnumKeyByEnumValue(GopMethodEnumSearchSupplier, doc.gopMethod) as GopMethodEnumSearchSupplier,
        gopRemarks: doc.gopRemark,
        gopAgentId: 0,
        gopContacts: this.convertToContactList(gopPrimaryContact),
        gopContact: gopPrimaryContact?.contact_fullName,
        geoCoordinates: {
          latitude: parseFloat(geoCoords[0]),
          longitude: parseFloat(geoCoords[1])
        },
        searchString: formattedSearchString,
        score: doc.score,
        ranking: doc.ranking,
        phoneNumbers: [
          { type: PhoneNumberType.Fax, number: doc.fax || '' },
          { type: PhoneNumberType.Fixed, number: doc.telephone || '' }
        ] as PhoneNumber[],
        email: doc.email,
        contactPersons: contactsNotPrimaryAndAutoImport.map(contact => {
          return new ContactPerson({
            firstName: contact.contact_fullName,
            surname: contact.contact_lastName,
            email: contact.contact_email,
            remarks: contact.contact_remark,
            phoneNumbers: [
              { type: PhoneNumberType.Fixed, number: contact.contact_telephone || '' },
              { type: PhoneNumberType.Fax, number: contact.contact_fax || '' }
            ] as PhoneNumber[],
            preferredChannelCode: this.getEnumKeyByEnumValue(PreferredChannel, contact.contact_preferredContactMethod) as PreferredChannel,
            preferredLanguageCode: contact.contact_language,
            propertyTypes: contact.contact_roles.map(role => {
              return { businessKey: this.extractFromList(role, ';', 0) };
            })
          });
        })
      };
    });

    return {
      searchSupplier: true,
      caseId: caseId,
      caseNumber: '',
      uniqueId: docs[0].id,
      username: loggedInUser?.userId,
      providers: providers
    };
  }



}
